@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$default: #121413;
$beige: #e9e9e5;
$lightBeige: #fafbfa;
$darkBeige: #d3d3cd;

body {
  font-family: 'Open Sans', sans-serif;
  color: $default;
}

h1,
h2,
h3,
h4,
h5 {
  color: $default;
}

.default-html {
  p {
    margin-bottom: 1.2rem;
    @media only screen and (max-width: 768px) {
      margin-bottom: 0.5rem;
    }
  }
  a {
    color: #857551;
    text-decoration: underline;
    transition: 0.1s all ease-in-out;
    &:hover {
      color: #f70d1a;
    }
  }
  ul {
    list-style: disc;
    margin-left: 1.5rem;
    margin-bottom: 1.2rem;

    @media only screen and (max-width: 768px) {
      margin-bottom: 0.5rem;
    }

    li {
    }
  }

  img {
    max-width: 100%;
  }
  h1 {
    font-size: 45px;
    margin-bottom: 25px;
    @media only screen and (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
  h2 {
    font-size: 35px;
    margin-bottom: 15px;
    @media only screen and (max-width: 600px) {
      font-size: 25px;
    }
  }
  h3 {
    font-size: 25px;
    margin-bottom: 15px;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  h4 {
    margin-bottom: 10px;
  }
}
.filename-ellipsis {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
